<template>
  <BackButton :to="{name: 'Family'}" />
  <h1 class="display-1 mb-m">{{ $t('family.member.add-member-title')}}</h1>
  <form class="family-member-add-new__container" :class="[errors && 'form has-errors', isLoading && 'is-loading']" ref="form">
    <div class="family-member-add-new__grid mb-m">
      <div class="mb-l">
        <InputLabel class="mb-s">{{ $t('family.member.first-name')}}</InputLabel>
        <InputText @invalid="invalidateForm" v-model="firstName" required />
      </div>
      <div>
        <InputLabel class="mb-s">{{ $t('family.member.last-name')}}</InputLabel>
        <InputText @invalid="invalidateForm" v-model="lastName" required />
      </div>
    </div>
    <div class="mb-l">
      <InputLabel class="mb-s">{{ $t('family.member.relationship')}}</InputLabel>
      <InputRadio v-model="relationship" class="family-add-member__radio mr-l" group="relationship" inputId="spouse" @invalid="invalidateForm" required>Spouse</InputRadio>
      <InputRadio v-model="relationship" class="family-add-member__radio mr-l" group="relationship" inputId="partner">Partner</InputRadio>
      <InputRadio v-model="relationship" class="family-add-member__radio mr-l" group="relationship" inputId="child">Child</InputRadio>
      <InputRadio v-model="relationship" class="family-add-member__radio mr-l" group="relationship" inputId="other">Other</InputRadio>
    </div>
    <div class="mb-l">
      <InputLabel class="mb-s">{{ $t('family.member.gender')}}</InputLabel>
      <InputRadio v-model="gender" class="family-add-member__radio mr-l" group="gender" inputId="female"  @invalid="invalidateForm" required>Female</InputRadio>
      <InputRadio v-model="gender" class="family-add-member__radio mr-l" group="gender" inputId="male">Male</InputRadio>
    </div>
    <div class="family-member-add-new__grid mb-l">
      <div class="mb-l">
        <InputLabel class="mb-s">{{ $t('family.member.matricule-number')}}</InputLabel>
        <InputText @invalid="invalidateForm" v-model="socialSecurityNumber" required />
      </div>
      <div>
        <InputCheckbox v-model="hasLuxemburgishSocialSecurityNumber" name="relationship" inputId="social-security" @invalid="invalidateForm">
          {{ $t('family.member.no-matricule-number')}}
        </InputCheckbox>
      </div>
    </div>
    <div class="family-member-add-new__grid mb-l">
      <div>
        <InputLabel class="mb-s">{{ $t('family.member.health-insurance')}}</InputLabel>
        <InputSelect
          required
          :options="healthInsurancesService.healthInsurances"
          @invalid="invalidateForm"
          v-model="healthInsurance"
          :placeholder="$t('family.member.health-insurance.placeholder')"
        />
      </div>
    </div>
    <div class="family-member-add-new__grid mb-l">
      <div>
        <InputLabel class="mb-s">{{ $t('family.member.health-mutual')}}</InputLabel>
        <InputSelect
            :options="healthMutualsService.healthMutuals"
            @invalid="invalidateForm"
            v-model="healthMutual"
            :placeholder="$t('family.member.health-mutual.placeholder')"
        />
      </div>
      <div class="mb-l" v-if="healthMutual === 'other'">
        <InputLabel class="mb-s">{{ $t('family.member.other')}}</InputLabel>
        <InputText @invalid="invalidateForm" v-model="healthMutualOther" required />
      </div>
    </div>
    <div class="family-member-add-new__grid mb-xl">
      <div>
        <InputLabel class="mb-s">{{ $t('family.member.email')}}</InputLabel>
        <InputText @invalid="invalidateForm" v-model="email" />
      </div>
    </div>
    <div class="family-add-member__submit-button mb-l">
      <Button type="submit" @click="submit($event)">{{ $t('family.member.add-member-button')}}</Button>
    </div>
  </form>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import Button from '@/01-atoms/Button.vue'
import InputCheckbox from '@/01-atoms/Input-checkbox.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import router from '@/router'
import { postFamilyMember } from '@/api/family/members-api'
import { NewMember } from '@/services/family/MemberService'
import { inject } from 'vue'
import { ErrorHandlerKey, HealthInsuranceKey, HealthMutualKey } from '@/services/serviceTypes'

@Options({
  name: 'Add member',
  components: {
    InputLabel,
    InputText,
    InputRadio,
    InputSelect,
    Button,
    InputCheckbox,
    BackButton
  }
})
export default class FamilyMemberAddNew extends Vue {
  firstName = ''
  lastName = ''
  relationship = ''
  gender = ''
  hasLuxemburgishSocialSecurityNumber = true
  socialSecurityNumber = 0
  healthInsurance = ''
  healthMutual = ''
  healthMutualOther = ''
  email = ''
  certificate = ''

  errors = false
  isLoading = false

  healthMutualsService = inject(HealthMutualKey)
  healthInsurancesService = inject(HealthInsuranceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.healthMutualsService?.loadHealthMutuals()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    this.healthInsurancesService?.loadHealthInsurances()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  submit (ev: Event) {
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      ev.preventDefault()
      this.createNewMember()
    }
  }

  createNewMember () {
    const member: NewMember = {
      firstName: this.firstName,
      lastName: this.lastName,
      relationship: this.relationship,
      gender: this.gender,
      hasLuxemburgishSocialSecurityNumber: this.hasLuxemburgishSocialSecurityNumber,
      socialSecurityNumber: this.socialSecurityNumber,
      healthInsurance: this.healthInsurance,
      healthMutual: this.healthMutual,
      healthMutualOther: this.healthMutualOther,
      email: this.email,
      certificate: this.certificate
    }
    this.postNewMember(member)
  }

  postNewMember (member: NewMember) {
    this.isLoading = true
    postFamilyMember(member)
      .then(() => {
        this.isLoading = false
        router.push({ name: 'Add member confirmation' })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  invalidateForm () {
    this.errors = true
  }
}
</script>
<style lang="scss" scoped>
.family-member-add-new__container {
  max-width: 700px;
}

.family-member-add-new__grid {
  display: grid;
  grid-gap: $medium;
  grid-template-columns: 1fr 1fr;

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.family-add-member__radio {
  @include breakpoint(large down) {
    display: block;
  }
}

.family-add-member__submit-button {
  @include breakpoint(large down) {
    text-align: center;
  }
}
</style>
