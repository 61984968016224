
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Switch from '@/01-atoms/Switch.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Button from '@/01-atoms/Button.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import { Member } from '@/services/family/MemberService'
import { FamilyMemberAccountPreference, getFamilyMemberAccountPreferences, patchFamilyMemberAccountPreferences } from '@/api/family/members-api'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import eventHub from '@/event-hub'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--account-preferences',
  components: {
    Card,
    Switch,
    IconButton,
    Button,
    HelpText
  }
})
export default class FamilyMemberAccountPreferences extends Vue.with(Props) {
  preferences = {} as FamilyMemberAccountPreference

  mounted () {
    this.loadAccountPreferences()
  }

  errorHandlerService = inject(ErrorHandlerKey)

  loadAccountPreferences () {
    const id = this.$route.params.id
    getFamilyMemberAccountPreferences(id)
      .then((results) => {
        this.preferences = results.data
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  updateSetting (preference: string, checked: boolean) {
    this.preferences[preference] = checked
    const id = this.$route.params.id
    patchFamilyMemberAccountPreferences(id, this.preferences)
      .then(() => {
        eventHub.$emit('show-notification', preference)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
