
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

@Options({
  name: 'Search',
  components: {
    Icon
  }
})
export default class Search extends Vue { }
