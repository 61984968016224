<template>
  <div>
    <h1 class="display-1 mb-m">{{ $t('inquiries.title')}}</h1>
    <div class="family-member-requests__box">
      <Search class="mb-m"></Search>
      API Endpoint for future release
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Search from '@/02-molecules/Search.vue'

@Options({
  name: 'Family-member--inquiries',
  components: {
    Search
  }
})
export default class FamilyMemberRequests extends Vue { }
</script>
<style lang="scss" scoped>
  .family-member-requests__box {
    @include white-box;
    padding: 40px;
  }
</style>
