
import { Options, Vue } from 'vue-class-component'
import FamilyMember from '@/03-organisms/Family-member.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, MemberServiceKey } from '@/services/serviceTypes'

@Options({
  name: 'Family',
  components: {
    FamilyMember,
    Icon,
    Button
  }
})
export default class Family extends Vue {
  loading = true
  familyMembersService = inject(MemberServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  placeholderMember = {
    id: '',
    type: 'main',
    firstName: 'Placeholder',
    lastName: 'Name',
    matricule: '123456789',
    birthDate: '1992-11-30T00:00:00.000Z'
  }

  mounted () {
    this.familyMembersService?.loadMembers()
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
