<template>
  <CardEdit :class="[ isLoading && 'is-loading', 'mb-m']"
            @edit-card="isEditing = true"
            @save-card="saveBasicInformation"
            @discard-card="discardBasicInformation">
    <template #title>{{ $t('family.member.basic-information')}}</template>
    <template #left>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.social-security-number')}}</span>
      <span class="grey-dark d-b mb-l fw-700 placeholder-animation">
        {{ member.socialSecurityNumber.length ? member.socialSecurityNumber : '/' }}
      </span>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.health-insurance')}}</span>
      <span class="grey-dark d-b fw-700 placeholder-animation" v-if="!isEditing">
        {{ getHealthInsuranceLabel() }}
      </span>
      <InputSelect v-else
                   class="mb-l"
                   v-model="healthInsurance"
                   :options="healthInsurancesService?.healthInsurances"
                   :selected="member.healthInsurance" />
    </template>
    <template #right>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.relationship')}}</span>
      <span class="grey-dark d-b mb-l fw-700 placeholder-animation" v-if="!isEditing">
        {{ member.civilStatus.length ? civilStatusLabel : '/' }}
      </span>
      <div v-else>
        <InputSelect class="mb-m"
                     v-model="civilStatus"
                     :options="civilStatusOptions"
                     :selected="member.civilStatus"
        ></InputSelect>
        <InputUpload class="mb-m">{{ $t('family.member.civilStatus-certificate')}}</InputUpload>
        <InputText :placeholder="$t('family.member.partner-email')" class="mb-l" v-model="partnerEmail"></InputText>
      </div>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.mutuality')}}</span>
      <span class="grey-dark d-b fw-700 placeholder-animation" v-if="!isEditing">
        {{ getHealthMutualLabel() }}
      </span>
      <InputSelect v-else
                   class="mb-l"
                   v-model="healthMutual"
                   :options="healthMutualsService?.healthMutuals"
                   :selected="member.healthMutual"/>
    </template>
    <div style="display: none">
      {{ $t('family.member.civilStatus.single') }}
      {{ $t('family.member.civilStatus.married') }}
      {{ $t('family.member.civilStatus.partnership') }}
      {{ $t('family.member.civilStatus.divorced') }}
      {{ $t('family.member.civilStatus.widowed') }}
    </div>
  </CardEdit>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Member } from '@/services/family/MemberService'
import { useI18n } from 'vue-i18n'
import { patchFamilyMember } from '@/api/family/members-api'
import CardEdit from '@/02-molecules/Card-edit.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import removeEmptyProps from '@/helpers/remove-empty-props'
import { inject } from 'vue'
import { ErrorHandlerKey, HealthInsuranceKey, HealthMutualKey } from '@/services/serviceTypes'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details-basic-information',
  components: {
    CardEdit,
    InputText,
    InputUpload,
    InputLabel,
    InputSelect
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetailsBasicInformation extends Vue.with(Props) {
  healthInsurance = ''
  healthMutual = ''
  civilStatus = ''
  certificate = ''
  partnerEmail = ''

  isLoading = false
  isEditing = false

  healthMutualsService = inject(HealthMutualKey)
  healthInsurancesService = inject(HealthInsuranceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.healthMutualsService?.loadHealthMutuals()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    this.healthInsurancesService?.loadHealthInsurances()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  getHealthInsuranceLabel () {
    let optionIndex = -1
    if (this.member?.healthInsurance.length) {
      optionIndex = this.healthInsurancesService?.healthInsurances.findIndex((option) => option.id === this.member?.healthInsurance)!
      if (optionIndex !== -1) {
        return this.healthInsurancesService?.healthInsurances[optionIndex]!.name!
      }
    }
    return '/'
  }

  getHealthMutualLabel () {
    let optionIndex = -1
    if (this.member?.healthMutual.length) {
      optionIndex = this.healthMutualsService?.healthMutuals.findIndex((option) => option.id === this.member?.healthMutual)!
      if (optionIndex !== -1) {
        return this.healthMutualsService?.healthMutuals[optionIndex].name!
      }
    }
    return '/'
  }

  get civilStatusLabel () {
    if (this.member?.civilStatus.length) {
      const optionIndex = this.civilStatusOptions.findIndex((option) => option.id === this.member?.civilStatus)
      if (optionIndex !== -1) {
        return this.civilStatusOptions[optionIndex].title
      }
    }
    return '/'
  }

  get civilStatusOptions () {
    const { t } = useI18n()
    return [
      { id: 'single', title: t('family.member.civilStatus.single') },
      { id: 'married', title: t('family.member.civilStatus.married') },
      { id: 'partnership', title: t('family.member.civilStatus.partnership') },
      { id: 'divorced', title: t('family.member.civilStatus.divorced') },
      { id: 'widowed', title: t('family.member.civilStatus.widowed') }
    ]
  }

  saveBasicInformation () {
    this.isLoading = true
    this.isEditing = false
    const basicInformation = {
      healthInsurance: this.healthInsurance!,
      healthMutual: this.healthMutual!,
      civilStatus: this.civilStatus!,
      certificate: '',
      partnerEmail: ''
    }
    patchFamilyMember(this.member?.id, removeEmptyProps(basicInformation))
      .then(() => {
        this.$emit('load-member')
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  discardBasicInformation () {
    this.isEditing = false
    this.healthInsurance = ''
    this.healthMutual = ''
    this.civilStatus = ''
    this.certificate = ''
    this.partnerEmail = ''
  }
}
</script>
<style lang="scss" scoped>
.family-member--personal-details__edit-address-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $xmedium;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
  }
}
</style>
