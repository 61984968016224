
import { Options, Vue } from 'vue-class-component'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import Button from '@/01-atoms/Button.vue'
import InputCheckbox from '@/01-atoms/Input-checkbox.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import router from '@/router'
import { postFamilyMember } from '@/api/family/members-api'
import { NewMember } from '@/services/family/MemberService'
import { inject } from 'vue'
import { ErrorHandlerKey, HealthInsuranceKey, HealthMutualKey } from '@/services/serviceTypes'

@Options({
  name: 'Add member',
  components: {
    InputLabel,
    InputText,
    InputRadio,
    InputSelect,
    Button,
    InputCheckbox,
    BackButton
  }
})
export default class FamilyMemberAddNew extends Vue {
  firstName = ''
  lastName = ''
  relationship = ''
  gender = ''
  hasLuxemburgishSocialSecurityNumber = true
  socialSecurityNumber = 0
  healthInsurance = ''
  healthMutual = ''
  healthMutualOther = ''
  email = ''
  certificate = ''

  errors = false
  isLoading = false

  healthMutualsService = inject(HealthMutualKey)
  healthInsurancesService = inject(HealthInsuranceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.healthMutualsService?.loadHealthMutuals()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    this.healthInsurancesService?.loadHealthInsurances()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  submit (ev: Event) {
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      ev.preventDefault()
      this.createNewMember()
    }
  }

  createNewMember () {
    const member: NewMember = {
      firstName: this.firstName,
      lastName: this.lastName,
      relationship: this.relationship,
      gender: this.gender,
      hasLuxemburgishSocialSecurityNumber: this.hasLuxemburgishSocialSecurityNumber,
      socialSecurityNumber: this.socialSecurityNumber,
      healthInsurance: this.healthInsurance,
      healthMutual: this.healthMutual,
      healthMutualOther: this.healthMutualOther,
      email: this.email,
      certificate: this.certificate
    }
    this.postNewMember(member)
  }

  postNewMember (member: NewMember) {
    this.isLoading = true
    postFamilyMember(member)
      .then(() => {
        this.isLoading = false
        router.push({ name: 'Add member confirmation' })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  invalidateForm () {
    this.errors = true
  }
}
