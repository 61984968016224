<template>
  <BackButton v-if="showOnResponsive('navigation')" :to="{name: 'Family'}" fallback />
  <div :class="[ loading ? 'is-loading' : '', 'family-member__grid']">
    <div v-if="showOnResponsive('navigation')">
      <h1 class="display-1 mb-l">{{ $t('family.member.profile')}}</h1>
      <Tag class="mb-l placeholder-animation" icon="user" :label="member.type" />
      <span class="display-3 d-b family-member__name placeholder-animation">{{ member.firstName }} {{ member.lastName }}</span>
      <ul class="family-member__menu">
        <li v-for="item in memberNavigation" v-bind:key="item.name">
          <router-link
              active-class="is-active"
              class="family-member__menu__item fs-20"
              :to="{name: item.name , params: { id: member.id }}"
          >
            {{ $t(item.label) }}
          </router-link>
        </li>
      </ul>
      <div class="family-member__request-number mb-xl placeholder-animation">
        <span class="fs-20">{{ $t('family.member.number-of-requests')}}:</span>
        <span class="family-member__request-number__number ta-center fs-20 fw-700">
          {{ member.totalRequestNumber }}
        </span>
      </div>
      <FamilyMemberCard :member="member" />
    </div>
    <div class="family-member__main" v-if="showOnResponsive('main')">
      <BackButton :to="{name: 'Family member' , params: { id: member.matricule }}" class="family-member__responsive-back-button" />
      <router-view :member="member" @load-member="loadMember"/>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import FamilyMemberCard from '@/03-organisms/Family-member-card.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import checkMediaQuery from '@/helpers/media-query'
import variables from '../../scss/js-variables.scss'
import { getFamilyMember } from '@/api/family/members-api'
import { Member } from '@/services/family/MemberService'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

@Options({
  name: 'Family-member',
  components: {
    Tag,
    FamilyMemberCard,
    BackButton
  },
  emits: ['load-member']
})
export default class FamilyMember extends Vue {
  loading = true
  member: Member = {
    id: 'placeholderId',
    type: '',
    status: '',
    statusNote: '',
    firstName: '',
    lastName: '',
    matricule: '',
    birthDate: '',
    socialSecurityNumber: '',
    healthInsurance: '',
    healthMutual: '',
    civilStatus: '',
    totalRequestNumber: 0,
    email: '',
    phone: '',
    address: {
      number: '',
      street: '',
      zipCode: '',
      city: '',
      country: ''
    },
    paymentFrequency: '',
    bankAccounts: [
      {
        id: '',
        name: '',
        bic: '',
        iban: '',
        reimbursementAccount: false,
        domiciliationAccount: false
      }
    ]
  }

  memberNavigation = [
    {
      name: 'Personal details',
      label: 'family.member.personal-details'
    },
    {
      name: 'Account preferences',
      label: 'family.member.account-preferences'
    },
    {
      name: 'Family member inquiries',
      label: 'navigation.inquiries'
    },
    {
      name: 'Family member documents',
      label: 'navigation.documents'
    }
  ]

  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.loadMember()
  }

  loadMember () {
    this.loading = true
    const id = this.$route['params'].id
    getFamilyMember(id)
      .then((response) => {
        this.member = response.data
        this.loading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  showOnResponsive (element: string) {
    let showContent = true
    if (checkMediaQuery(`(max-width: ${variables.familyMemberBreakpoint})`)) {
      showContent = false
      if (element === 'navigation' && this.isRoot) {
        showContent = true
      }
      if (element === 'main' && !this.isRoot) {
        showContent = true
      }
    }
    return showContent
  }

  get isRoot () {
    return this.$route.name === 'Family member'
  }
}
</script>
<style lang="scss" scoped>
$chevron-color: encodecolor($blue-dark);

.family-member__grid {

  @include breakpoint($family-member-breakpoint up) {
    display: grid;
    grid-gap: $xmedium;
    grid-template-columns: 365px 1fr;
  }
}

.family-member__menu {
  border-top: 2px solid $blue-dark-10;
  border-bottom: 2px solid $blue-dark-10;
  margin: 30px 0;
  padding: 10px 0;
}

.family-member__menu__item {
  color: $blue-dark;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Cpath fill='#{$chevron-color}' d='M10.71,6.29A1,1,0,0,0,9.29,7.71L14.59,13l-5.3,5.29a1,1,0,0,0,1.42,1.42l6-6a1,1,0,0,0,0-1.42Z'/%3E%3C/svg%3E") no-repeat 100% 50% / 24px;
  display: block;
  padding: 10px 0;

  &.is-active {
    opacity: 1;
    text-decoration: underline;
    text-shadow: 1px 0px 0px $blue-dark;
  }

  @include breakpoint($family-member-breakpoint up) {
    opacity: 0.5;
  }
}

.family-member__request-number {
  display: grid;
  grid-gap: $xmedium;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.family-member__request-number__number {
  background-color: $blue-dark-10;
  border-radius: 15px;
  height: 62px;
  line-height: 62px;
}

.family-member__responsive-back-button {
  @include breakpoint($family-member-breakpoint up) {
    display: none;
  }
}

.family-member__name {
  display: block;
  min-height: 24px;
}
</style>
