
import { Options, Vue } from 'vue-class-component'
import InputLabel from '@/01-atoms/Input-label.vue'
import Separator from '@/02-molecules/Separator.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Button from '@/01-atoms/Button.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import { Member } from '@/services/family/MemberService'
import { deleteFamilyMember } from '@/api/family/members-api'
import removeEmptyProps from '@/helpers/remove-empty-props'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--remove-member',
  components: {
    InputLabel,
    Separator,
    HelpText,
    IconButton,
    Button,
    InputRadio,
    InputUpload,
    InputText
  }
})
export default class FamilyMemberRemoveMember extends Vue.with(Props) {
  reason = ''
  email = ''
  certificate = ''
  reasonOther = ''

  errorHandlerService = inject(ErrorHandlerKey)

  changeTab () {
    this.resetFields()
  }

  resetFields () {
    this.email = ''
    this.certificate = ''
    this.reasonOther = ''
  }

  clickDelete () {
    const deleteInformation = {
      'reason': this.reason!,
      'partnerEmail': this.email!,
      'certificate': '',
      'reasonOther': this.reasonOther!
    }

    deleteFamilyMember(this.member?.id, removeEmptyProps(deleteInformation))
      .then(() => {
        this.$router.push({ name: 'Remove member confirmation' })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
