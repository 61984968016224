const removeEmptyProps = (entity = {}) => {
  for (const propName in entity) {
    if (entity[propName] === '') {
      delete entity[propName]
    }
  }
  return entity
}

export default removeEmptyProps
