<template>
  <div>
    <h1 class="display-1 mb-l">{{ $t('family.member.personal-details')}}</h1>
    <FamilyMemberPersonalDetailsBasicInformation :member="member" @load-member="loadMember"/>
    <FamilyMemberPersonalDetailsContactInformation :member="member" @load-member="loadMember"/>
    <FamilyMemberPersonalDetailsPaymentInformation :member="member" @load-member="loadMember"/>
    <IconButton
      to="remove-member"
      class="mb-l"
      background="red-15"
      icon="trash-2"
      v-if="member.type !== 'main'"
    >
      {{ $t('family.member.remove-member')}}
    </IconButton>
    <HelpText icon="question-circle" class="mb-m">{{ $t('family.member.change-information')}}</HelpText>
    <Button :to="{name: 'Support'}" >{{ $t('global.contact-us')}}</Button>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CardEdit from '@/02-molecules/Card-edit.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import { Member } from '@/services/family/MemberService'
import { BankAccount } from '@/services/family/BankAccountService'
import FamilyMemberPersonalDetailsBasicInformation from '@/04-pages/03-Family/Family-member--personal-details-basic-information.vue'
import FamilyMemberPersonalDetailsContactInformation from '@/04-pages/03-Family/Family-member--personal-details-contact-information.vue'
import FamilyMemberPersonalDetailsPaymentInformation from '@/04-pages/03-Family/Family-member--personal-details-payment-information.vue'

interface PaymentInformation {
  memberId: string;
  paymentFrequency: string;
  bankAccounts: Array<BankAccount>;
}

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details',
  components: {
    FamilyMemberPersonalDetailsBasicInformation,
    FamilyMemberPersonalDetailsContactInformation,
    FamilyMemberPersonalDetailsPaymentInformation,
    CardEdit,
    IconButton,
    HelpText,
    Button,
    InputText,
    InputUpload,
    InputRadio,
    InputLabel,
    InputSelect
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetails extends Vue.with(Props) {
  loadMember () {
    this.$emit('load-member')
  }
}
</script>
