
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import FamilyMemberCard from '@/03-organisms/Family-member-card.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import checkMediaQuery from '@/helpers/media-query'
import variables from '../../scss/js-variables.scss'
import { getFamilyMember } from '@/api/family/members-api'
import { Member } from '@/services/family/MemberService'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

@Options({
  name: 'Family-member',
  components: {
    Tag,
    FamilyMemberCard,
    BackButton
  },
  emits: ['load-member']
})
export default class FamilyMember extends Vue {
  loading = true
  member: Member = {
    id: 'placeholderId',
    type: '',
    status: '',
    statusNote: '',
    firstName: '',
    lastName: '',
    matricule: '',
    birthDate: '',
    socialSecurityNumber: '',
    healthInsurance: '',
    healthMutual: '',
    civilStatus: '',
    totalRequestNumber: 0,
    email: '',
    phone: '',
    address: {
      number: '',
      street: '',
      zipCode: '',
      city: '',
      country: ''
    },
    paymentFrequency: '',
    bankAccounts: [
      {
        id: '',
        name: '',
        bic: '',
        iban: '',
        reimbursementAccount: false,
        domiciliationAccount: false
      }
    ]
  }

  memberNavigation = [
    {
      name: 'Personal details',
      label: 'family.member.personal-details'
    },
    {
      name: 'Account preferences',
      label: 'family.member.account-preferences'
    },
    {
      name: 'Family member inquiries',
      label: 'navigation.inquiries'
    },
    {
      name: 'Family member documents',
      label: 'navigation.documents'
    }
  ]

  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.loadMember()
  }

  loadMember () {
    this.loading = true
    const id = this.$route['params'].id
    getFamilyMember(id)
      .then((response) => {
        this.member = response.data
        this.loading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  showOnResponsive (element: string) {
    let showContent = true
    if (checkMediaQuery(`(max-width: ${variables.familyMemberBreakpoint})`)) {
      showContent = false
      if (element === 'navigation' && this.isRoot) {
        showContent = true
      }
      if (element === 'main' && !this.isRoot) {
        showContent = true
      }
    }
    return showContent
  }

  get isRoot () {
    return this.$route.name === 'Family member'
  }
}
