
import { Options, Vue } from 'vue-class-component'
import { Member } from '@/services/family/MemberService'
import CardEdit from '@/02-molecules/Card-edit.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import { postBankAccount, patchBankAccount } from '@/api/family/bank-accounts-api'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { inject } from 'vue'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details-basic-information',
  components: {
    CardEdit,
    InputText,
    InputUpload,
    InputLabel,
    InputSelect,
    InputRadio,
    IconButton
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetailsPaymentInformation extends Vue.with(Props) {
  isLoading = false
  isEditing = false
  showNewBankAccount = false

  newBankAccount = {
    name: '',
    iban: '',
    bic: '',
    bankAccountDetailsFile: 'placeholderfile'
  }

  editableBankAccounts = this.member?.bankAccounts

  errorHandlerService = inject(ErrorHandlerKey)

  savePaymentInformation () {
    this.isLoading = true
    this.isEditing = false

    if (this.editableBankAccounts?.length) {
      this.editableBankAccounts?.forEach((account, index) => {
        this.updateBankAccount(account, index)
      })
    }
  }

  updateBankAccount (account: any, index: number) {
    if (this.hasAccountNameChanged(account, index)) {
      this.isLoading = true
      const patchedBankAccount = {
        memberId: this.member?.id,
        name: account.name!,
        bic: account.bic!,
        iban: account.iban!,
        bankAccountDetailsFile: 'placeholder'
      }

      patchBankAccount(this.member?.bankAccounts[index].id, patchedBankAccount)
        .then(() => {
          this.$emit('load-member')
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    } else {
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    }
  }

  hasAccountNameChanged (account: any, index: number) {
    return account.name !== this.member?.bankAccounts[index].name
  }

  addNewBankAccount () {
    this.isLoading = true
    const bankAccount = {
      memberId: this.member?.id,
      name: this.newBankAccount.name,
      iban: this.newBankAccount.iban,
      bic: this.newBankAccount.bic,
      bankAccountDetailsFile: this.newBankAccount.bankAccountDetailsFile
    }

    postBankAccount(bankAccount)
      .then(() => {
        this.$emit('load-member')
        this.isLoading = false
        this.discardPaymentInformation()
      })
  }

  discardPaymentInformation () {
    this.showNewBankAccount = false
    this.isEditing = false
    this.newBankAccount = {
      name: '',
      iban: '',
      bic: '',
      bankAccountDetailsFile: 'placeholderfile'
    }
  }

  editBankAccounts () {
    this.isEditing = true
    this.editableBankAccounts = JSON.parse(JSON.stringify(this.member?.bankAccounts))
  }
}
