
import { Options, Vue } from 'vue-class-component'
import Search from '@/02-molecules/Search.vue'

@Options({
  name: 'Family-member--documents',
  components: {
    Search
  }
})
export default class FamilyMemberDocuments extends Vue { }
