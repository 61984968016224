import axios from 'axios'

export const getFileDownload = async (url: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: url,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getFileDownloads = async (fileType: 'default' | 'cns' = 'default', currentPage = 1, itemPerPage = 5) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: 'uploads/files',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    params: {
      fileType: fileType,
      currentPage: currentPage,
      itemPerPage: itemPerPage
    },
    data: {}
  })
}

/**
 *
 * @param file = new FormData
 * @param file.file
 * @param file.title
 * @param file.fileType 'default' || 'cns'
 */

export const postUploadsFile = async (file: any) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/uploads/files',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${bearer}`
    },
    data: file
  })
}
