<template>
  <button :class="`select ${selectClass}`" v-if="modal === ''" type="button">
    <span :value="placeholderValue" v-if="placeholderValue !== ''">{{ placeholderValue }}</span>
    <span v-else>{{ $t('new-inquiry.category-placeholder') }}</span>
  </button>
  <select
      id=""
      v-else
      name=""
      ref="select-input"
      @change="selectOption"
      :class="`select ${selectClass}`"
      :required="required === '' ? 'required' : false"
      @input="$emit('update:modelValue', $event.target.value)"
  >
    <option value="" v-if="placeholderValue !== ''">{{ placeholderValue }}</option>
    <option
      :value="option.id"
      v-for="option in options"
      :key="option.id"
      :selected="selected === option.id"
    >{{ option.title }}{{ option.name }}</option>
  </select>
</template>

<script lang="ts">
import { Vue, prop, Options } from 'vue-class-component'
import eventHub from '@/event-hub'

export interface SelectOption {
  id: string;
  title?: string;
  name?: string;
}

class Props {
  background?: string
  modal?: string
  required?: string
  placeholder = prop<string>({ default: '' })
  selected?: string
  options = prop<SelectOption>({
    default: [
      {
        id: '192302',
        title: 'Option A',
        name: 'Option A'
      },
      {
        id: '192312',
        title: 'Option B',
        name: 'Option A'
      }
    ]
  })
}

@Options({
  name: 'Input-select',
  emits: ['select-option', 'update:modelValue']
})

/**
 * @param {string} placeholder - First option
 * @param {array} options - [{id: '', title: ''}]
 * @param {string=} background - Default: transparent, white
 * @param {attribute=} modal - Use as button to open Modal
 * @param {attribute=} required
 * @param {string=} selected - string of id
 */
export default class InputSelect extends Vue.with(Props) {
  get selectClass () {
    if (this.background) {
      return `background background--${this.background}`
    }
    return ''
  }

  selectOption (ev: Event) {
    const select: any = ev.target
    const selection = select.value
    this.$emit('select-option', selection)
  }

  resetSelect () {
    const selectInput: any = this.$refs['select-input']
    selectInput.selectedIndex = '0'
  }

  get placeholderValue () {
    return this.placeholder
  }

  mounted () {
    eventHub.$on('reset-select', () => {
      this.resetSelect()
    })

    eventHub.$on('select-topic', (id: string) => {
      this.selectTopicFromParent(id)
    })
  }

  selectTopicFromParent (id: string) {
    const select: any = this.$refs['select-input']

    if (this.optionsContainId(id)) {
      select.value = id
      this.$emit('select-option', id)
    }
  }

  optionsContainId (id: string) {
    // this.options typescript error ?!
    /* @ts-ignore */
    return this.options.some((item) => item.id === id)
  }
}
</script>

<style lang="scss" scoped>
$chevron-color: encodecolor($blue-dark);

.select {
  @include brandon-grotesque;
  appearance: none;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Cpath fill='#{$chevron-color}' d='M18.29,9.29,13,14.59,7.71,9.29a1,1,0,0,0-1.42,1.42l6,6a1,1,0,0,0,1.42,0l6-6a1,1,0,0,0-1.42-1.42Z'/%3E%3C/svg%3E") no-repeat 100% 50% / 24px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid $blue-dark;
  border-left: 0;
  border-radius: 0;
  color: $grey-dark;
  display: inline-block;
  font-size: 16px;
  text-align: left;
  padding: 6px 0;
  width: 100%;

  &::placeholder {
    color: $grey-dark;
  }
}

.background {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$chevron-color}' viewBox='0 0 26 26'%3E%3Cpath d='M18.29,9.29,13,14.59,7.71,9.29a1,1,0,0,0-1.42,1.42l6,6a1,1,0,0,0,1.42,0l6-6a1,1,0,0,0-1.42-1.42Z'/%3E%3C/svg%3E") no-repeat calc(100% - 12px) 50% / 24px;
  border-bottom: 0;
  border-radius: 30px;
  padding: 12px 21px;
}

.form.has-errors {
  select:invalid {
    border-bottom: 1px solid $red;
  }
}
</style>
