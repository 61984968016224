<template>
  <CardEdit :class="[ isLoading && 'is-loading', 'mb-m']"
            @edit-card="editBankAccounts"
            @save-card="savePaymentInformation"
            @discard-card="discardPaymentInformation"
            v-if="member.bankAccounts && member.bankAccounts.length">
    <template #title>{{ $t('family.member.payment-detail')}}</template>
    <template #center>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.payment-frequency')}}</span>
      <span class="grey-dark d-b mb-l fw-700 placeholder-animation" v-if="!isEditing">
          {{ member.paymentFrequency.length ? member.paymentFrequency : '/' }}
        </span>
      <div class="mb-l" v-else>
        <InputRadio class="mr-l" inputId="m" group="freq" :checked="member.paymentFrequency === 'monthly' && ''">
          {{ $t('family.member.payment-frequency.monthly')}}
        </InputRadio>
        <InputRadio class="mr-l" inputId="by" group="freq" :checked="member.paymentFrequency === 'bi-annual' && ''">
          {{ $t('family.member.payment-frequency.bi-annual')}}
        </InputRadio>
        <InputRadio class="mr-l" inputId="y" group="freq" :checked="member.paymentFrequency === 'yearly' && ''">
          {{ $t('family.member.payment-frequency.annual')}}
        </InputRadio>
      </div>
      <div>
        <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.accounts')}}</span>
        <div class="card__table_grid mb-l placeholder-animation">
          <div v-for="(item, index) in member.bankAccounts" v-bind:key="item.id">
            <div class="mb-m" v-if="isEditing">
              <InputRadio class="mr-l" :inputId="item.id + 'reimburse'" :group="item.id + 'activated'" checked>
                {{ $t('family.member.reimbursement-account')}}
              </InputRadio>
              <InputRadio class="mr-l" :inputId="item.id + 'inactive'" :group="item.id + 'activated'">
                {{ $t('family.member.reimbursement-account.inactive')}}
              </InputRadio>
            </div>
            <table class="card__table">
              <tr>
                <th><span class="d-b fw-700">{{ $t('family.member.reimbursement-account')}}</span></th>
              </tr>
              <tr>
                <td>
                  <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.bank-account-name')}}</span>
                  <span class="d-b fw-700" v-if="!isEditing">{{ item.name }}</span>
                  <InputText v-else v-model="editableBankAccounts[index].name" :value="item.name" />
                </td>
              </tr>
              <tr>
                <td>
                  <div v-if="item.iban">
                    <span class="fs-14 tt-uppercase mb-s mr-m">{{ $t('family.member.iban')}}</span>
                    <span class="mb-s fw-700">{{ item.iban }}</span>
                  </div>
                  <div v-if="item.bic">
                    <span class="fs-14 tt-uppercase mr-m">{{ $t('family.member.bic')}}</span>
                    <span class="mb-s fw-700">{{ item.bic }}</span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="mb-m" v-if="!showNewBankAccount">
          <IconButton @click="showNewBankAccount = true" icon="plus" background="blue-dark-10">
            {{ $t('family.member.add-bank-account-button')}}
          </IconButton>
        </div>
        <div v-else>
          <div class="mb-l">
            <InputLabel for="name" small class="mb-s">Account Name</InputLabel>
            <InputText id="name" class="mb-m" v-model="newBankAccount.name" />
            <InputLabel for="iban" small class="mb-s">Iban</InputLabel>
            <InputText id="iban" class="mb-m" v-model="newBankAccount.iban" />
            <InputLabel for="bic" small class="mb-s">Bic</InputLabel>
            <InputText id="bic" class="mb-m" v-model="newBankAccount.bic" />
            <InputUpload>Upload RIP</InputUpload>
          </div>
          <IconButton @click="addNewBankAccount" icon="plus" background="blue-dark-10">
            Confirm
          </IconButton>
        </div>
      </div>
    </template>
  </CardEdit>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Member } from '@/services/family/MemberService'
import CardEdit from '@/02-molecules/Card-edit.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import { postBankAccount, patchBankAccount } from '@/api/family/bank-accounts-api'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { inject } from 'vue'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details-basic-information',
  components: {
    CardEdit,
    InputText,
    InputUpload,
    InputLabel,
    InputSelect,
    InputRadio,
    IconButton
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetailsPaymentInformation extends Vue.with(Props) {
  isLoading = false
  isEditing = false
  showNewBankAccount = false

  newBankAccount = {
    name: '',
    iban: '',
    bic: '',
    bankAccountDetailsFile: 'placeholderfile'
  }

  editableBankAccounts = this.member?.bankAccounts

  errorHandlerService = inject(ErrorHandlerKey)

  savePaymentInformation () {
    this.isLoading = true
    this.isEditing = false

    if (this.editableBankAccounts?.length) {
      this.editableBankAccounts?.forEach((account, index) => {
        this.updateBankAccount(account, index)
      })
    }
  }

  updateBankAccount (account: any, index: number) {
    if (this.hasAccountNameChanged(account, index)) {
      this.isLoading = true
      const patchedBankAccount = {
        memberId: this.member?.id,
        name: account.name!,
        bic: account.bic!,
        iban: account.iban!,
        bankAccountDetailsFile: 'placeholder'
      }

      patchBankAccount(this.member?.bankAccounts[index].id, patchedBankAccount)
        .then(() => {
          this.$emit('load-member')
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    } else {
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    }
  }

  hasAccountNameChanged (account: any, index: number) {
    return account.name !== this.member?.bankAccounts[index].name
  }

  addNewBankAccount () {
    this.isLoading = true
    const bankAccount = {
      memberId: this.member?.id,
      name: this.newBankAccount.name,
      iban: this.newBankAccount.iban,
      bic: this.newBankAccount.bic,
      bankAccountDetailsFile: this.newBankAccount.bankAccountDetailsFile
    }

    postBankAccount(bankAccount)
      .then(() => {
        this.$emit('load-member')
        this.isLoading = false
        this.discardPaymentInformation()
      })
  }

  discardPaymentInformation () {
    this.showNewBankAccount = false
    this.isEditing = false
    this.newBankAccount = {
      name: '',
      iban: '',
      bic: '',
      bankAccountDetailsFile: 'placeholderfile'
    }
  }

  editBankAccounts () {
    this.isEditing = true
    this.editableBankAccounts = JSON.parse(JSON.stringify(this.member?.bankAccounts))
  }
}
</script>
<style lang="scss" scoped>
.card__table {
  width: 100%;
  border-collapse: collapse;

th,
td {
  border: 1px solid $blue-dark-10;
  padding: 8px 15px;
  text-align: left;
}
}

.card__table_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $xmedium;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
    grid-gap: $medium;
  }
}
</style>
