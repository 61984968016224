<template>
  <h1 class="family__title display-1 mb-l">{{ $t('family.title')}}</h1>
  <div :class="[loading && 'is-loading', 'family__grid mb-m']">
    <FamilyMember v-if="loading" :member="placeholderMember"></FamilyMember>
    <FamilyMember v-for="member in familyMembersService?.members" v-bind:key="member.id" :member="member"></FamilyMember>
    <router-link :to="{ name: 'Add member'}" class="family__grid__empty ta-center">
      <span class="display-3 mb-m family__grid__empty__label">{{ $t('family.add-member-button')}}</span>
      <Icon icon="plus-circle" background="blue-dark"></Icon>
    </router-link>
  </div>
  <div class="family__extra">
    <div class="family__extra__box">
      <h2 class="display-3">{{ $t('family.co-member-box.title')}}</h2>
      <div class="format-text mb-l fs-14 family__extra__box__text grey-dark">
        <ul v-html="$t('family.co-member-box.text')" />
      </div>
      <Button to="/" >{{ $t('global.learn-more-button')}}</Button>
    </div>
    <div class="family__extra__box">
      <h2 class="display-3">{{ $t('family.third-party-box.title')}}</h2>
      <div class="format-text mb-l fs-14 family__extra__box__text grey-dark">
        <p>{{ $t('family.third-party-box.text')}}</p>
      </div>
      <Button to="/" >{{ $t('global.contact-cmcm-button')}}</Button>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import FamilyMember from '@/03-organisms/Family-member.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, MemberServiceKey } from '@/services/serviceTypes'

@Options({
  name: 'Family',
  components: {
    FamilyMember,
    Icon,
    Button
  }
})
export default class Family extends Vue {
  loading = true
  familyMembersService = inject(MemberServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  placeholderMember = {
    id: '',
    type: 'main',
    firstName: 'Placeholder',
    lastName: 'Name',
    matricule: '123456789',
    birthDate: '1992-11-30T00:00:00.000Z'
  }

  mounted () {
    this.familyMembersService?.loadMembers()
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
</script>
<style lang="scss" scoped>
.family__grid {
  display: grid;
  grid-gap: $xmedium;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @include breakpoint($responsive-navigation-breakpoint down) {
    grid-gap: $medium;
  }
}

.family__grid__empty {
  border: 4px dashed $blue-dark-10;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 390px;

  @include breakpoint(medium down) {
    padding: 20px 10px;
    min-height: unset;
  }
}

.family__grid__empty__label {
  max-width: 140px;
}

.family__extra {
  display: grid;
  grid-gap: $xmedium;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  & > div:last-child {
    grid-column: span 2;

    @include breakpoint(large down) {
      grid-column: span 1;
    }
  }

  @include breakpoint(large down) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
    grid-gap: $medium;
  }
}

.family__extra__box {
  padding: 30px 50px;
  position: relative;

  @include breakpoint(medium down) {
    padding: 15px;
  }

  &:first-child::after {
    content: '';
    background-color: $blue-dark-10;
    position: absolute;
    top: 30px;
    right: -15px;
    bottom: 30px;
    width: 2px;

    @include breakpoint(medium down) {
      display: none;
    }
  }
}

.family__extra__box__text {
  max-width: 420px;

  ul {
    list-style: disc;
    padding-left: 20px;
  }
}

.family__title {
  @include breakpoint($responsive-navigation-breakpoint down) {
    display: none;
  }
}
</style>
