<template>
  <div class="family-member__aside">
    <h1 class="display-1 mb-l">{{ $t('family.member.account-preferences')}}</h1>
    <Card class="mb-m">
      <template #title>{{ $t('family.member.personal-details')}}</template>
      <Switch @switch="updateSetting" id="ownProfileEdit" :checked="preferences['ownProfileEdit'] ? '' : null" locked>
        {{ $t('family.member.account-preferences.access-own-information')}}
      </Switch>
      <Switch @switch="updateSetting" id="changeAddressAndBankAccount" :checked="preferences['changeAddressAndBankAccount'] ? '' : null">
        {{ $t('family.member.account-preferences.change-address')}}
      </Switch>
      <Switch @switch="updateSetting" id="childrenInformationAccess" :checked="preferences['childrenInformationAccess'] ? '' : null">
        {{ $t('family.member.account-preferences.access-to-minor')}}
      </Switch>
    </Card>
    <Card class="mb-l">
      <template #title>{{ $t('inquiries.title')}}</template>
      <Switch @switch="updateSetting" id="allUploadAccess" :checked="preferences['allUploadAccess'] ? '' : null">
        {{ $t('family.member.account-preferences.access-all-inquiries')}}
      </Switch>
      <Switch @switch="updateSetting" id="childrenUploadAccess" :checked="preferences['childrenUploadAccess'] ? '' : null">
        {{ $t('family.member.account-preferences.access-minor-inquiries')}}
      </Switch>
    </Card>
    <IconButton
      to="remove-member"
      class="mb-l"
      background="red-15"
      icon="trash-2"
      v-if="member.type !== 'main'"
    >
      {{ $t('family.member.remove-member')}}
    </IconButton>
    <HelpText icon="question-circle" class="mb-m">{{ $t('family.member.change-information')}}</HelpText>
    <Button :to="{name: 'Support'}" >{{ $t('global.contact-us')}}</Button>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Switch from '@/01-atoms/Switch.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Button from '@/01-atoms/Button.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import { Member } from '@/services/family/MemberService'
import { FamilyMemberAccountPreference, getFamilyMemberAccountPreferences, patchFamilyMemberAccountPreferences } from '@/api/family/members-api'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import eventHub from '@/event-hub'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--account-preferences',
  components: {
    Card,
    Switch,
    IconButton,
    Button,
    HelpText
  }
})
export default class FamilyMemberAccountPreferences extends Vue.with(Props) {
  preferences = {} as FamilyMemberAccountPreference

  mounted () {
    this.loadAccountPreferences()
  }

  errorHandlerService = inject(ErrorHandlerKey)

  loadAccountPreferences () {
    const id = this.$route.params.id
    getFamilyMemberAccountPreferences(id)
      .then((results) => {
        this.preferences = results.data
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  updateSetting (preference: string, checked: boolean) {
    this.preferences[preference] = checked
    const id = this.$route.params.id
    patchFamilyMemberAccountPreferences(id, this.preferences)
      .then(() => {
        eventHub.$emit('show-notification', preference)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
</script>
<style lang="scss" scoped>
.family-member__aside {
  max-width: 540px;
}
</style>
