<template>
  <CardEdit :class="[ isLoading && 'is-loading', 'mb-m']"
            @edit-card="isEditing = true"
            @save-card="saveContactInformation"
            @discard-card="discardContactInformation">
    <template #title>{{ $t('family.member.contact-information')}}</template>
    <template #left>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.contact')}}</span>
      <span class="fs-14 tt-uppercase d-b mb-s fw-700">{{ $t('family.member.email')}}</span>
      <span class="grey-dark d-b mb-l fw-700 placeholder-animation" v-if="!isEditing">
          {{ member.email.length ? member.email : '/' }}
        </span>
      <InputText type="email" class="mb-l" :value="member.email" v-model="email" v-else />
      <span class="fs-14 tt-uppercase d-b mb-s fw-700">{{ $t('family.member.phone-number')}}</span>
      <span class="grey-dark d-b fw-700 placeholder-animation" v-if="!isEditing">
          {{ member.phone ? member.phone : '/' }}
        </span>
      <InputText type="tel" class="mb-l" :value="member.phone" v-model="phone" v-else />
    </template>
    <template v-if="member.address" #right>
      <span class="fs-14 tt-uppercase d-b mb-s">{{ $t('family.member.address')}}</span>
      <span class="grey-dark d-b mb-l fw-700 placeholder-animation" v-if="!isEditing">
              {{ member.address.number }}{{ member.address.number.length ? ',' : '' }} {{ member.address.street }} <br>
              {{ member.address.zipCode }} {{ member.address.city }} <br>
              {{ member.address.country }}
        </span>
      <div v-else>
        <div class="family-member--personal-details__edit-address-item">
          <div>
            <InputLabel small class="mb-s">{{ $t('family.member.address.number')}}</InputLabel>
            <InputText type="number" class="mb-l" :value="member.address.number" v-model="address.number" />
          </div>
          <div>
            <InputLabel small class="mb-s">{{ $t('family.member.address.street')}}</InputLabel>
            <InputText class="mb-l" :value="member.address.street" v-model="address.street" />
          </div>
        </div>
        <div class="family-member--personal-details__edit-address-item">
          <div>
            <InputLabel small class="mb-s">{{ $t('family.member.address.postal-code')}}</InputLabel>
            <InputText type="number" class="mb-l" :value="member.address.zipCode" v-model="address.zipCode" />
          </div>
          <div>
            <InputLabel small class="mb-s">{{ $t('family.member.address.city')}}</InputLabel>
            <InputText class="mb-l" :value="member.address.city" v-model="address.city" />
          </div>
        </div>
        <InputLabel small class="mb-s">{{ $t('family.member.address.country')}}</InputLabel>
        <InputText class="mb-m" :value="member.address.country" v-model="address.country" />
        <InputUpload uploadId="residence-certificate" class="mb-m">
          {{ $t('family.member.address.residence-certificate')}}
        </InputUpload>
        <InputRadio group="changeAddress" inputId="all" v-model="address.applyForAllMembers" checked>
          {{ $t('family.member.address.change-for-all')}}
        </InputRadio>
        <br>
        <InputRadio group="changeAddress" v-model="address.applyForAllMembers" inputId="single">
          {{ $t('family.member.address.change-for-person')}}
        </InputRadio>
      </div>
    </template>
  </CardEdit>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Member } from '@/services/family/MemberService'
import { patchFamilyMemberContact } from '@/api/family/members-api'
import CardEdit from '@/02-molecules/Card-edit.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details-basic-information',
  components: {
    CardEdit,
    InputText,
    InputUpload,
    InputLabel,
    InputSelect,
    InputRadio
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetailsContactInformation extends Vue.with(Props) {
  email = ''
  phone = ''

  address = {
    number: '',
    street: '',
    zipCode: '',
    city: '',
    country: '',
    applyForAllMembers: 'all'
  }

  isLoading = false
  isEditing = false

  errorHandlerService = inject(ErrorHandlerKey)

  removeEmptyProps (entity = {}) {
    for (const propName in entity) {
      if (entity[propName] === '') {
        delete entity[propName]
      } else if (typeof entity[propName] === 'object') {
        this.buildAddress(entity[propName])
      }
    }
    return entity
  }

  buildAddress (address = {}) {
    for (const childPropName in address) {
      if (this.address[childPropName] !== '') {
        address[childPropName] = this.address[childPropName]
      } else {
        address[childPropName] = this.member?.address[childPropName]
      }
    }
    address['applyForAllMembers'] = this.address.applyForAllMembers === 'all'
  }

  saveContactInformation (id: string) {
    this.isLoading = true
    this.isEditing = false
    const contactInformation = {
      email: this.email!,
      phone: this.phone!,
      address: {
        number: this.address.number!,
        street: this.address.street!,
        city: this.address.city!,
        zipCode: this.address.zipCode!,
        country: this.address.country!,
        applyForAllMembers: true,
        residenceCertificate: ''
      }
    }

    patchFamilyMemberContact(this.member?.id, this.removeEmptyProps(contactInformation))
      .then(() => {
        this.$emit('load-member')
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  discardContactInformation () {
    this.isEditing = false
    this.email = ''
    this.phone = ''

    this.address = {
      number: '',
      street: '',
      zipCode: '',
      city: '',
      country: '',
      applyForAllMembers: 'all'
    }
  }
}
</script>
