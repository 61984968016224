<template>
  <div>
    <h1 class="display-1 mb-m">{{ $t('family.member.remove-member')}}</h1>
    <div class="family-member-remove-member__box">
      <h2 class="display-2 mb-l mt-0">{{ $t('family.member.remove-member.reason-question', {member: 'Gilles'})}}</h2>
      <div class="mb-l">
        <InputLabel class="mb-m">{{ $t('family.member.remove-member.reason-placeholder')}}</InputLabel>
        <InputRadio @change-radio-input="changeTab" v-model="reason" class="mr-l" inputId="education_finished" group="reason">Finish Education</InputRadio>
        <InputRadio @change-radio-input="changeTab" v-model="reason" class="mr-l" inputId="divorce" group="reason">Divorce</InputRadio>
        <InputRadio @change-radio-input="changeTab" v-model="reason" class="mr-l" inputId="separation" group="reason">Separation</InputRadio>
        <InputRadio @change-radio-input="changeTab" v-model="reason" inputId="other" group="reason">Other</InputRadio>
      </div>
      <div v-if="reason === 'education_finished'" class="family-member-remove-member__reason">
        <InputLabel for="child-email">{{ $t('family.member.remove-member.email')}}</InputLabel>
        <InputText id="child-email" v-model="email" />
      </div>
      <div v-if="reason === 'divorce'" class="family-member-remove-member__reason">
        <div class="mb-l">
          <InputLabel class="mb-m">{{ $t('family.member.remove-member.document')}}</InputLabel>
          <InputUpload uploadId="divorcePaper"></InputUpload>
        </div>
        <InputLabel for="divorce-email" class="mb-m">{{ $t('family.member.remove-member.email')}}</InputLabel>
        <InputText id="divorce-email" v-model="email" />
      </div>
      <div v-if="reason === 'separation'" class="family-member-remove-member__reason">
        <InputLabel>Separation</InputLabel>
      </div>
      <div v-if="reason === 'other'" class="family-member-remove-member__reason">
        <InputLabel for="other">Other</InputLabel>
        <InputText id="other" placeholder="Please give a reason" v-model="reasonOther" />
      </div>
      <Separator></Separator>
      <HelpText class="mb-xl">
        {{ $t('family.member.remove-member.help-text')}}
      </HelpText>
      <div class="family-member-remove-member__actions">
        <IconButton @click="clickDelete" background="red-15" icon="trash-2" medium>
          {{ $t('family.member.remove-member')}}
        </IconButton>
        <Button :to="{name: 'Family'}" class="lg-up">{{ $t('global.cancel')}}</Button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import InputLabel from '@/01-atoms/Input-label.vue'
import Separator from '@/02-molecules/Separator.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Button from '@/01-atoms/Button.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import { Member } from '@/services/family/MemberService'
import { deleteFamilyMember } from '@/api/family/members-api'
import removeEmptyProps from '@/helpers/remove-empty-props'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--remove-member',
  components: {
    InputLabel,
    Separator,
    HelpText,
    IconButton,
    Button,
    InputRadio,
    InputUpload,
    InputText
  }
})
export default class FamilyMemberRemoveMember extends Vue.with(Props) {
  reason = ''
  email = ''
  certificate = ''
  reasonOther = ''

  errorHandlerService = inject(ErrorHandlerKey)

  changeTab () {
    this.resetFields()
  }

  resetFields () {
    this.email = ''
    this.certificate = ''
    this.reasonOther = ''
  }

  clickDelete () {
    const deleteInformation = {
      'reason': this.reason!,
      'partnerEmail': this.email!,
      'certificate': '',
      'reasonOther': this.reasonOther!
    }

    deleteFamilyMember(this.member?.id, removeEmptyProps(deleteInformation))
      .then(() => {
        this.$router.push({ name: 'Remove member confirmation' })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
</script>
<style lang="scss" scoped>
.family-member-remove-member__box {
  @include white-box;
}

.family-member-remove-member__actions {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: $medium;

  @include breakpoint(xmedium down) {
    display: block;
    text-align: center;
  }
}

.family-member-remove-member__reason {
  max-width: 500px;
}
</style>
