
import { Options, Vue } from 'vue-class-component'
import Confirmation from '@/03-organisms/Confirmation.vue'
import Button from '@/01-atoms/Button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, MemberServiceKey } from '@/services/serviceTypes'

@Options({
  name: 'Add family member confirmation',
  components: {
    Confirmation,
    Button
  }
})
export default class FamilyMemberRemoveCoMemberConfirmation extends Vue {
  familyMembersService = inject(MemberServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.familyMembersService?.loadMembers(true)
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
