
import { Vue, prop, Options } from 'vue-class-component'
import eventHub from '@/event-hub'

export interface SelectOption {
  id: string;
  title?: string;
  name?: string;
}

class Props {
  background?: string
  modal?: string
  required?: string
  placeholder = prop<string>({ default: '' })
  selected?: string
  options = prop<SelectOption>({
    default: [
      {
        id: '192302',
        title: 'Option A',
        name: 'Option A'
      },
      {
        id: '192312',
        title: 'Option B',
        name: 'Option A'
      }
    ]
  })
}

@Options({
  name: 'Input-select',
  emits: ['select-option', 'update:modelValue']
})

/**
 * @param {string} placeholder - First option
 * @param {array} options - [{id: '', title: ''}]
 * @param {string=} background - Default: transparent, white
 * @param {attribute=} modal - Use as button to open Modal
 * @param {attribute=} required
 * @param {string=} selected - string of id
 */
export default class InputSelect extends Vue.with(Props) {
  get selectClass () {
    if (this.background) {
      return `background background--${this.background}`
    }
    return ''
  }

  selectOption (ev: Event) {
    const select: any = ev.target
    const selection = select.value
    this.$emit('select-option', selection)
  }

  resetSelect () {
    const selectInput: any = this.$refs['select-input']
    selectInput.selectedIndex = '0'
  }

  get placeholderValue () {
    return this.placeholder
  }

  mounted () {
    eventHub.$on('reset-select', () => {
      this.resetSelect()
    })

    eventHub.$on('select-topic', (id: string) => {
      this.selectTopicFromParent(id)
    })
  }

  selectTopicFromParent (id: string) {
    const select: any = this.$refs['select-input']

    if (this.optionsContainId(id)) {
      select.value = id
      this.$emit('select-option', id)
    }
  }

  optionsContainId (id: string) {
    // this.options typescript error ?!
    /* @ts-ignore */
    return this.options.some((item) => item.id === id)
  }
}
