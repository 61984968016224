
import { Options, Vue } from 'vue-class-component'
import { Member } from '@/services/family/MemberService'
import { useI18n } from 'vue-i18n'
import { patchFamilyMember } from '@/api/family/members-api'
import CardEdit from '@/02-molecules/Card-edit.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import removeEmptyProps from '@/helpers/remove-empty-props'
import { inject } from 'vue'
import { ErrorHandlerKey, HealthInsuranceKey, HealthMutualKey } from '@/services/serviceTypes'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details-basic-information',
  components: {
    CardEdit,
    InputText,
    InputUpload,
    InputLabel,
    InputSelect
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetailsBasicInformation extends Vue.with(Props) {
  healthInsurance = ''
  healthMutual = ''
  civilStatus = ''
  certificate = ''
  partnerEmail = ''

  isLoading = false
  isEditing = false

  healthMutualsService = inject(HealthMutualKey)
  healthInsurancesService = inject(HealthInsuranceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.healthMutualsService?.loadHealthMutuals()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    this.healthInsurancesService?.loadHealthInsurances()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  getHealthInsuranceLabel () {
    let optionIndex = -1
    if (this.member?.healthInsurance.length) {
      optionIndex = this.healthInsurancesService?.healthInsurances.findIndex((option) => option.id === this.member?.healthInsurance)!
      if (optionIndex !== -1) {
        return this.healthInsurancesService?.healthInsurances[optionIndex]!.name!
      }
    }
    return '/'
  }

  getHealthMutualLabel () {
    let optionIndex = -1
    if (this.member?.healthMutual.length) {
      optionIndex = this.healthMutualsService?.healthMutuals.findIndex((option) => option.id === this.member?.healthMutual)!
      if (optionIndex !== -1) {
        return this.healthMutualsService?.healthMutuals[optionIndex].name!
      }
    }
    return '/'
  }

  get civilStatusLabel () {
    if (this.member?.civilStatus.length) {
      const optionIndex = this.civilStatusOptions.findIndex((option) => option.id === this.member?.civilStatus)
      if (optionIndex !== -1) {
        return this.civilStatusOptions[optionIndex].title
      }
    }
    return '/'
  }

  get civilStatusOptions () {
    const { t } = useI18n()
    return [
      { id: 'single', title: t('family.member.civilStatus.single') },
      { id: 'married', title: t('family.member.civilStatus.married') },
      { id: 'partnership', title: t('family.member.civilStatus.partnership') },
      { id: 'divorced', title: t('family.member.civilStatus.divorced') },
      { id: 'widowed', title: t('family.member.civilStatus.widowed') }
    ]
  }

  saveBasicInformation () {
    this.isLoading = true
    this.isEditing = false
    const basicInformation = {
      healthInsurance: this.healthInsurance!,
      healthMutual: this.healthMutual!,
      civilStatus: this.civilStatus!,
      certificate: '',
      partnerEmail: ''
    }
    patchFamilyMember(this.member?.id, removeEmptyProps(basicInformation))
      .then(() => {
        this.$emit('load-member')
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  discardBasicInformation () {
    this.isEditing = false
    this.healthInsurance = ''
    this.healthMutual = ''
    this.civilStatus = ''
    this.certificate = ''
    this.partnerEmail = ''
  }
}
