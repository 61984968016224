
import { Options, Vue } from 'vue-class-component'
import { Member } from '@/services/family/MemberService'
import { patchFamilyMemberContact } from '@/api/family/members-api'
import CardEdit from '@/02-molecules/Card-edit.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'

class Props {
  member?: Member;
}

@Options({
  name: 'Family-member--personal-details-basic-information',
  components: {
    CardEdit,
    InputText,
    InputUpload,
    InputLabel,
    InputSelect,
    InputRadio
  },
  emits: ['load-member']
})

export default class FamilyMemberPersonalDetailsContactInformation extends Vue.with(Props) {
  email = ''
  phone = ''

  address = {
    number: '',
    street: '',
    zipCode: '',
    city: '',
    country: '',
    applyForAllMembers: 'all'
  }

  isLoading = false
  isEditing = false

  errorHandlerService = inject(ErrorHandlerKey)

  removeEmptyProps (entity = {}) {
    for (const propName in entity) {
      if (entity[propName] === '') {
        delete entity[propName]
      } else if (typeof entity[propName] === 'object') {
        this.buildAddress(entity[propName])
      }
    }
    return entity
  }

  buildAddress (address = {}) {
    for (const childPropName in address) {
      if (this.address[childPropName] !== '') {
        address[childPropName] = this.address[childPropName]
      } else {
        address[childPropName] = this.member?.address[childPropName]
      }
    }
    address['applyForAllMembers'] = this.address.applyForAllMembers === 'all'
  }

  saveContactInformation (id: string) {
    this.isLoading = true
    this.isEditing = false
    const contactInformation = {
      email: this.email!,
      phone: this.phone!,
      address: {
        number: this.address.number!,
        street: this.address.street!,
        city: this.address.city!,
        zipCode: this.address.zipCode!,
        country: this.address.country!,
        applyForAllMembers: true,
        residenceCertificate: ''
      }
    }

    patchFamilyMemberContact(this.member?.id, this.removeEmptyProps(contactInformation))
      .then(() => {
        this.$emit('load-member')
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  discardContactInformation () {
    this.isEditing = false
    this.email = ''
    this.phone = ''

    this.address = {
      number: '',
      street: '',
      zipCode: '',
      city: '',
      country: '',
      applyForAllMembers: 'all'
    }
  }
}
